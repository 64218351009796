import React, { useState } from 'react';
import Layout from '../components/Layout';
import NavOne from '../components/NavOne';
import ModalVideo from 'react-modal-video';
import PageHeader from '../components/PageHeader';
import ClientStripe from '../assets/images/circle-stripe.png';
import skin from '../assets/images/videos/skinHealth.png';
import g5 from '../assets/images/2022/mabo/5.jpeg';
import g3 from '../assets/images/2022/mabo/3.jpeg';
import testimonial1 from '../assets/images/videos/testimonial2.png';
import worldleprosy from '../assets/images/videos/worldleprosy.png';
import Depression from '../assets/images/videos/depression.png';
import mentalHealth from '../assets/images/videos/mentalHealth.png';
import pregnancyAwareness from '../assets/images/videos/pregnancyawareness.png';
import grandMarch from '../assets/images/videos/grandmarch.png';
import parkison from '../assets/images/videos/parkison.png';
import worldAutism from '../assets/images/videos/worldautism.png';
import worldCancer from '../assets/images/videos/worldcancer.png';
import worldHemophiliaday from '../assets/images/videos/worldhemophiliaday.png';
import worldEpilepsy from '../assets/images/videos/worldepilepsy.png';
import Disclaimer from '../assets/images/videos/Disclaimer.png';
import worldHyper from '../assets/images/videos/hyperday.png';
import healthInfrastructure from '../assets/images/videos/health-infrastructure.png';
import careAdvancement from '../assets/images/videos/health-care-advancements.png';
import inuagratesProject from '../assets/images/videos/inuagrates-project.png';
import Footer from '../components/Footer';

const Content = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAbike, setIsAbike] = useState(false);
  const [isAbike2, setIsAbike2] = useState(false);
  const [isTestimonial, setIsTestimonial] = useState(false);
  const [isWorldLeprosy, setIsWorldLeprosy] = useState(false);
  const [isDepression, setIsDepression] = useState(false);
  const [ismentalHealth, setIsmentalHealth] = useState(false);
  const [ispregnancyAwareness, setIspregnancyAwareness] = useState(false);
  const [isgrandMarch, setIsgrandMarch] = useState(false);
  const [isparkison, setIsparkison] = useState(false);
  const [isworldAutism, setIsworldAutism] = useState(false);
  const [isworldCancer, setIsworldCancer] = useState(false);
  const [isworldHemophiliaday, setIsworldHemophiliaday] = useState(false);
  const [isworldEpilepsy, setIsworldEpilepsy] = useState(false);
  const [isDisclaimer, setIsDisclaimer] = useState(false);
  const [isworldHyper, setIsworldHyper] = useState(false);
  const [ishealthInfrastructure, setIshealthInfrastructure] = useState(false);
  const [iscareAdvancement, setIscareAdvancement] = useState(false);
  const [isinuagratesProject, setIsinuagratesProject] = useState(false);

  return (
    <section className='about-one '>
      <img src={ClientStripe} className='about-one__circle' alt='' />
      <div className='container text-center'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>Videos</h2>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${inuagratesProject})` }}
            >
              <h2 className='video-three__title'>
              Inaugurates Six Projects <br />
                 to Mark One Year in Office
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isinuagratesProject}
                  videoId='FeWr-7Sg5ow'
                  onClose={() => setIsinuagratesProject(false)}
                />
                <div
                  onClick={() => setIsinuagratesProject(true)}
                  onKeyDown={() => setIsinuagratesProject(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${careAdvancement})` }}
            >
              <h2 className='video-three__title'>
                One Year of <br />
                Health Care Advancement in LUTH
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={iscareAdvancement}
                  videoId='Gtpm778FbdE'
                  onClose={() => setIscareAdvancement(false)}
                />
                <div
                  onClick={() => setIscareAdvancement(true)}
                  onKeyDown={() => setIscareAdvancement(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${healthInfrastructure})` }}
            >
              <h2 className='video-three__title'>
                FG Enhances LUTH <br />
                Health Infrastructure
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={ishealthInfrastructure}
                  videoId='V_nbONzhn40'
                  onClose={() => setIshealthInfrastructure(false)}
                />
                <div
                  onClick={() => setIshealthInfrastructure(true)}
                  onKeyDown={() => setIshealthInfrastructure(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldHyper})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Hypertension Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isworldHyper}
                  videoId='6ElbtYeytmQ'
                  onClose={() => setIsworldHyper(false)}
                />
                <div
                  onClick={() => setIsworldHyper(true)}
                  onKeyDown={() => setIsworldHyper(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${Disclaimer})` }}
            >
              <h2 className='video-three__title'>
                <br />
                Disclaimer
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isDisclaimer}
                  videoId='qIEmPx_vq-k'
                  onClose={() => setIsDisclaimer(false)}
                />
                <div
                  onClick={() => setIsDisclaimer(true)}
                  onKeyDown={() => setIsDisclaimer(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldAutism})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Autism Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isworldAutism}
                  videoId='mGfcJwPk07U'
                  onClose={() => setIsworldAutism(false)}
                />
                <div
                  onClick={() => setIsworldAutism(true)}
                  onKeyDown={() => setIsworldAutism(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldCancer})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Cancer Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isworldCancer}
                  videoId='yPO063jb2UQ'
                  onClose={() => setIsworldCancer(false)}
                />
                <div
                  onClick={() => setIsworldCancer(true)}
                  onKeyDown={() => setIsworldCancer(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${parkison})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Parkinson Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isparkison}
                  videoId='zTYeJdEc2rs'
                  onClose={() => setIsparkison(false)}
                />
                <div
                  onClick={() => setIsparkison(true)}
                  onKeyDown={() => setIsparkison(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldHemophiliaday})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Hemophilia Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isworldHemophiliaday}
                  videoId='xBMLAD08EBk'
                  onClose={() => setIsworldHemophiliaday(false)}
                />
                <div
                  onClick={() => setIsworldHemophiliaday(true)}
                  onKeyDown={() => setIsworldHemophiliaday(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${grandMarch})` }}
            >
              <h2 className='video-three__title'>
                Video on <br />
                Grand March
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isgrandMarch}
                  videoId='uoUWIVr9N5Q'
                  onClose={() => setIsgrandMarch(false)}
                />
                <div
                  onClick={() => setIsgrandMarch(true)}
                  onKeyDown={() => setIsgrandMarch(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldEpilepsy})` }}
            >
              <h2 className='video-three__title'>
                World <br />
                Epilepsy Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isworldEpilepsy}
                  videoId='uoUWIVr9N5Q'
                  onClose={() => setIsworldEpilepsy(false)}
                />
                <div
                  onClick={() => setIsworldEpilepsy(true)}
                  onKeyDown={() => setIsworldEpilepsy(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${testimonial1})` }}
            >
              <h2 className='video-three__title'>
                FIDA Member
                <br />
                Testimonial
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isTestimonial}
                  videoId='fsIAebmKEc0'
                  onClose={() => setIsTestimonial(false)}
                />
                <div
                  onClick={() => setIsTestimonial(true)}
                  onKeyDown={() => setIsTestimonial(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${g3})` }}
            >
              <h2 className='video-three__title' style={{ fontSize: 20 }}>
                NIDCOM, MABO and NAPAC-USA <br />
                visit
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isAbike}
                  videoId='A7sbG0_dJJA'
                  onClose={() => setIsAbike(false)}
                />
                <div
                  onClick={() => setIsAbike(true)}
                  onKeyDown={() => setIsAbike(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${g5})` }}
            >
              <h2 className='video-three__title' style={{ fontSize: 20 }}>
                NIDCOM, MABO and NAPAC-USA <br />
                visit
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isAbike2}
                  videoId='c_LmWpA-bUc'
                  onClose={() => setIsAbike2(false)}
                />
                <div
                  onClick={() => setIsAbike2(true)}
                  onKeyDown={() => setIsAbike2(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${skin})` }}
            >
              <h2 className='video-three__title'>
                World Skin <br />
                Health Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isOpen}
                  videoId='Hj3xhQlF-o0'
                  onClose={() => setIsOpen(false)}
                />
                <div
                  onClick={() => setIsOpen(true)}
                  onKeyDown={() => setIsOpen(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${worldleprosy})` }}
            >
              <h2 className='video-three__title'>
                World Leprosy <br />
                Day
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isWorldLeprosy}
                  videoId='8fIWIJRX6uI'
                  onClose={() => setIsWorldLeprosy(false)}
                />
                <div
                  onClick={() => setIsWorldLeprosy(true)}
                  onKeyDown={() => setIsWorldLeprosy(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${Depression})` }}
            >
              <h2 className='video-three__title'>
                Depression <br />
                -Dr. Alenkhe
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={isDepression}
                  videoId='DPSTnRVUPKQ'
                  onClose={() => setIsDepression(false)}
                />
                <div
                  onClick={() => setIsDepression(true)}
                  onKeyDown={() => setIsDepression(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${mentalHealth})` }}
            >
              <h2 className='video-three__title'>
                Mental Health <br />
                -Dr. Sodimu
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={ismentalHealth}
                  videoId='qRGNpeBHZQo'
                  onClose={() => setIsmentalHealth(false)}
                />
                <div
                  onClick={() => setIsmentalHealth(true)}
                  onKeyDown={() => setIsmentalHealth(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4'>
            <div
              className='video-three__box'
              style={{ backgroundImage: `url(${pregnancyAwareness})` }}
            >
              <h2 className='video-three__title'>
                Video on <br />
                Pregnancy Awareness
              </h2>
              <div className='video-three__btn-box'>
                <ModalVideo
                  channel='youtube'
                  isOpen={ispregnancyAwareness}
                  videoId='FhXUyGh4vWU'
                  onClose={() => setIspregnancyAwareness(false)}
                />
                <div
                  onClick={() => setIspregnancyAwareness(true)}
                  onKeyDown={() => setIspregnancyAwareness(true)}
                  role='button'
                  tabIndex={0}
                  className='video-three__popup'
                >
                  <i className='fas fa-play'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Videos = () => {
  return (
    <Layout pageTitle='Videos | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <Content />
      <Footer />
    </Layout>
  );
};

export default Videos;
